<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Inspection</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Panel</label>
                    <v-combobox
                        v-model="inspectionRequest.panelSelected"
                        :items="listConstructionPanel" 
                        :rules=[validations.required]
                        item-text="panelDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                        @change="filterStages()"
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Stage</label>
                    <v-combobox
                        v-model="inspectionRequest.stageSelected"
                        :items="listConstructionStagesFiltered" 
                        :rules=[validations.required]
                        item-text="stageDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Project</label>
                    <v-combobox
                        v-model="inspectionRequest.projectSelected"
                        :items="listProjects"
                        item-text="description"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        dense
                        @change="loadPermits()"
                    ></v-combobox>
                </v-col>
                <v-col
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Permits</label>
                    <v-combobox
                        v-model="inspectionRequest.listPermitsSelected"
                        :items="listPermits"
                        item-text="permitAndTitle"
                        item-value="id"
                        :rules=[validations.required]
                        multiple
                        small-chips
                        clearable
                        single-line
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Description</label>
                    <v-text-field
                        v-model="inspectionRequest.description" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <ActionButtons
                :request="inspectionRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            prefix: 'mdi mdi-',

            inspectionRequest: {
                id: 0,
                idServiceProject: 0,
                projectSelected: null,
                idPanel: 0,
                idStage: 0,
                panelSelected: null,
                stageSelected: null,
                listPermitsSelected: []
            },

            listProjects: [],
            listConstructionPanel: [],
            listConstructionStages: [],
            listConstructionStagesFiltered: [],
            listPermits: [],

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            },

            idPanel: {
                default: 0
            },

            idStage: {
                default: 0
            },

            idServiceProject: {
                default: 0
            }
        },

        methods: {

            filterStages() {

                if (this.inspectionRequest.panelSelected != null && this.inspectionRequest.panelSelected != undefined) {
                    this.inspectionRequest.stageSelected = null;
                    this.listConstructionStagesFiltered = this.listConstructionStages.filter(stage => stage.idPanel == this.inspectionRequest.panelSelected.id);
                }
            },

            async getLists() {

                this.listProjects = await this.$store.dispatch("serviceModule/ListProjectCompact", "-");
                this.listConstructionPanel = await this.$store.dispatch("serviceModule/ListConstructionPanel");
                this.listConstructionStages = await this.$store.dispatch("serviceModule/ListConstructionStages");
            },

            async loadPermits() {
                if (this.inspectionRequest.projectSelected != null && this.inspectionRequest.projectSelected != undefined) {
                    this.listPermits = await this.$store.dispatch("serviceModule/ListPermitsByProject", this.inspectionRequest.projectSelected.id);
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("inspectionModule/GetById", this.id);

                    if (response.success) {
                        this.inspectionRequest = response.result;

                        this.inspectionRequest.panelSelected = {
                            id: this.inspectionRequest.idPanel,
                            panelDescription: this.inspectionRequest.panelDescription
                        }

                        await this.filterStages();

                        this.inspectionRequest.stageSelected = {
                            id: this.inspectionRequest.idStage,
                            stageDescription: this.inspectionRequest.stageDescription
                        }

                        await this.loadPermits();
                    }
                }
                else {

                    if (this.idPanel != null && this.idPanel != undefined && this.idPanel != 0) {
                    
                        let panelSelected = this.listConstructionPanel.filter(pnl => pnl.id == this.idPanel)[0];
                        if (panelSelected != null && panelSelected != undefined) {
                            this.inspectionRequest.panelSelected = panelSelected;
                            await this.filterStages();
                        }
                    
                        let stageSelected = this.listConstructionStages.filter(stg => stg.id == this.idStage)[0];
                        if (stageSelected != null && stageSelected != undefined) {
                            this.inspectionRequest.stageSelected = stageSelected;
                        }

                        if (this.idServiceProject != null && this.idServiceProject != undefined && this.idServiceProject != 0) {
                            let projectSelected = this.listProjects.filter(prj => prj.id == this.idServiceProject)[0];

                            if (projectSelected != null && projectSelected != undefined) {
                                this.inspectionRequest.projectSelected = projectSelected;
                            }

                        }
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/inspection/inspectionList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.inspectionRequest.id = this.id;

                    if (this.inspectionRequest.projectSelected != null && this.inspectionRequest.projectSelected != null) {
                        this.inspectionRequest.idServiceProject = this.inspectionRequest.projectSelected.id
                    }

                    if (this.inspectionRequest.panelSelected != null && this.inspectionRequest.panelSelected != undefined && this.inspectionRequest.panelSelected.id != 0) {
                        this.inspectionRequest.idPanel = this.inspectionRequest.panelSelected.id;
                    }

                    if (this.inspectionRequest.stageSelected != null && this.inspectionRequest.stageSelected != undefined && this.inspectionRequest.stageSelected.id != 0) {
                        this.inspectionRequest.idStage = this.inspectionRequest.stageSelected.id;
                    }

                    if (this.inspectionRequest.listPermitsSelected != null && this.inspectionRequest.listPermitsSelected != undefined) {
                        this.inspectionRequest.idListPermits = '';

                        this.inspectionRequest.listPermitsSelected.forEach(item => {
                            this.inspectionRequest.idListPermits += `${item.id};`
                        });
                    }

                    const result = await this.$store.dispatch("inspectionModule/CreateUpdate", this.inspectionRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();                        
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>